<template>
  <v-container class="pa-0 category-tree-container">
    <div class="header d-flex d-md-none flex-column">
      <v-row no-gutters justify="end">
        <v-btn depressed class="white" @click="$emit('close')">
          <v-icon :large="!isCordova" :medium="isCordova">$close</v-icon>
        </v-btn>
      </v-row>
      <v-tabs
        v-model="selectedTab"
        class="px-1 mb-4"
        hide-slider
        grow
        active-class="activeTab"
      >
        <v-tab key="categories">
          <v-img eager contain src="/favicon.png" height="24" max-width="32" />
          <h3 class="text-none">{{ $t("navbar.products") }}</h3>
        </v-tab>
        <v-tab key="info" v-if="$vuetify.breakpoint.smAndDown && !isCordova">
          <h3 class="text-none">{{ $t("navbar.links") }}</h3>
        </v-tab>
      </v-tabs>
    </div>
    <v-tabs-items v-model="selectedTab" @click.stop>
      <v-tab-item key="categories">
        <v-list class="category-tree px-2 px-sm-0">
          <v-list-group
            v-for="category in categories"
            :key="category.categoryId"
            class="category1"
          >
            <template v-slot:activator>
              <v-list-item-title class="font-weight-bold">
                <router-link
                  :to="{
                    name: 'Category',
                    params: { pathMatch: category.slug }
                  }"
                  @click.native.prevent="goToCategory(category)"
                >
                  {{ category.name }}
                </router-link>
              </v-list-item-title>
            </template>
            <template v-for="category2 in category.categories">
              <v-list-item
                link
                :to="{
                  name: 'Category',
                  params: { pathMatch: category2.slug }
                }"
                class="category2"
                :key="category2.categoryId"
              >
                <v-list-item-title>{{ category2.name }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>
        </v-list>
      </v-tab-item>
      <v-tab-item key="info" v-if="$vuetify.breakpoint.smAndDown && !isCordova">
        <v-list class="info-tab px-2">
          <v-list-item
            v-for="link in infoLinks"
            :key="link.templateNavItemId"
            class="link accent mb-1"
            :href="link.itemUrl"
            @click="clicked"
          >
            <!-- <v-list-item-icon>
              <v-icon color="default"> {{ link.iconSource }} </v-icon>
            </v-list-item-icon> -->
            <v-list-item-title :class="link.classStyle" class="text--bold">
              {{ link.name }}
            </v-list-item-title>
          </v-list-item>
          <template v-for="item in menuLinks">
            <v-list-item
              v-if="item.children?.length <= 0"
              :key="item.templateNavItemId"
              class="menuItem"
              :href="item.itemUrl"
              @click="handleClickLink"
              :class="
                item.itemUrl && item.itemUrl.length > 0
                  ? 'link-enabled full'
                  : 'link-disabled'
              "
            >
              <v-list-item-title class="ml-0 w-100" :class="item.cssClass">
                {{ item.name }}
              </v-list-item-title>
            </v-list-item>
            <v-list-group
              class="menuItem"
              v-else
              :key="item.templateNavItemId"
              :class="
                item.itemUrl && item.itemUrl.length > 0
                  ? 'link-enabled full'
                  : 'link-disabled'
              "
            >
              <template v-slot:activator>
                <v-list-item-title>
                  <a
                    v-if="item.itemUrl && item.itemUrl.length > 0"
                    :href="item.itemUrl"
                    event
                    @click="handleClickLink"
                  >
                    {{ item.name }}</a
                  >
                  <span v-else>{{ item.name }}</span>
                </v-list-item-title>
              </template>
              <div
                v-for="child in filteredExcludedNavItemNavigationType(
                  item.templateNavItems,
                  [3]
                )"
                :key="child.templateNavItemId"
              >
                <v-list-item link :href="child.itemUrl" @click="clicked">
                  <v-list-item-title class="ml-1">
                    {{ child.name }}
                  </v-list-item-title>
                </v-list-item>
                <v-list
                  class="py-0"
                  dense
                  v-if="child.templateNavItems?.length"
                >
                  <v-list-item
                    v-for="child2 in child.templateNavItems"
                    :key="child2.templateNavItemId"
                    link
                    :href="child2.itemUrl || '#'"
                    @click="clicked"
                    class="text-caption default--text ml-3"
                    :class="child2.cssClass"
                  >
                    {{ child2.name }}
                  </v-list-item>
                </v-list>
              </div>
              <MenuBannerCard
                v-for="navItemBanner in filteredIncludedNavItemNavigationType(
                  item.templateNavItems,
                  [3]
                )"
                :key="navItemBanner.templateNavItemId"
                :bannerCard="navItemBanner"
              />
            </v-list-group>
          </template>
        </v-list>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<style lang="scss">
.link-enabled.full {
  a {
    width: 100%;
    display: block;
  }
}
</style>
<script>
import deliveryReactive from "~/mixins/deliveryReactive";
import clickHandler from "~/mixins/clickHandler";

import { mapState, mapActions } from "vuex";
import MenuBannerCard from "./MenuBannerCard.vue";
export default {
  name: "CategoryTree",
  mixins: [deliveryReactive, clickHandler],
  props: { menuLinks: { type: Array }, infoLinks: { type: Array } },
  data() {
    return {
      selectedTab: null,
      expandedKeys: [],
      selectedKey: null
    };
  },
  computed: {
    ...mapState({
      categories: ({ category }) => category.categoryTree
    })
  },
  methods: {
    ...mapActions({
      reload: "category/loadCategoryTree"
    }),
    filteredIncludedNavItemNavigationType(navItems, type = [3]) {
      return navItems.filter(
        navItem =>
          navItem.templateNavItemNavigationType &&
          navItem.templateNavItemNavigationType
            .templateNavItemNavigationTypeId &&
          type.includes(
            navItem.templateNavItemNavigationType
              .templateNavItemNavigationTypeId
          )
      );
    },
    filteredExcludedNavItemNavigationType(navItems, type = [3]) {
      return navItems.filter(
        navItem =>
          !navItem.templateNavItemNavigationType ||
          !navItem.templateNavItemNavigationType
            .templateNavItemNavigationTypeId ||
          !type.includes(
            navItem.templateNavItemNavigationType
              .templateNavItemNavigationTypeId
          )
      );
    },
    close() {
      this.$emit("close");
    },
    nop() {},
    goToCategory(category) {
      this.categoryOpened = null;
      this.$router.push({
        name: "Category",
        params: { pathMatch: category.slug }
      });
    },
    handleClickLink(event) {
      this.clicked(event);
      this.$emit("close");
    }
  },
  created() {
    this.reload(true);
  },
  components: { MenuBannerCard }
};
</script>

<style scoped lang="scss">
.category-tree-container {
  background-repeat: no-repeat;
  background-position: bottom right;

  .header {
    .v-tabs {
      position: relative;
      top: 1px;
      z-index: 1;
      :first-child {
        margin-right: 2px;
      }
      :last-child {
        margin-left: 2px;
      }
      .v-tab {
        width: 44%;
        color: $text-color !important;
        background-color: transparent;
        border-radius: 8px 8px 0 0;
        border: 2px solid var(--v-primary-base);
      }
      .v-tabs-slider-wrapper {
        color: transparent !important;
      }
      .v-tab--active {
        background-color: var(--v-primary-base);
        &::before {
          opacity: 0;
        }
      }
    }
  }
  .menuItem .v-list-item {
    padding-left: 0px;
  }
}
.category-tree {
  padding: 0;
  a {
    text-decoration: none;
  }
  .v-list-group {
    border-bottom: 1px solid $border-color;
  }
  .category1 {
    a {
      color: $text-color;
      font-size: 14px;
    }
    &.v-list-item--active {
      a {
        color: var(--v-primary-base);
        ::before {
          opacity: 0;
        }
      }
      &.v-list-group__header {
        color: var(--v-primary-base);
        ::before {
          opacity: 0;
        }
      }
    }
    .v-list-item__title {
      padding-left: 0;
      color: $text-color;
    }
  }
  .category2 {
    .v-list-item__title {
      font-size: 14px;
      text-transform: initial;
      color: $text-color !important;
    }
    &.v-list-item--active {
      background-color: transparent;
      color: transparent;
      .v-list-item__title {
        color: $text-color;
        font-weight: bold;
      }
      &:before {
        opacity: 1;
      }
    }
    &.v-list-item--link {
      padding-left: 24px;
    }
  }
}

.info-tab {
  padding: 0;
  .link {
    min-height: 40pt;
    .v-list-item__icon {
      margin: 0 !important;
      align-self: center;
      font-size: 30px;
    }
    .v-list-item__title {
      margin-left: 16px;
    }
  }
  .menuItem:has(div.v-list-group) {
    padding-left: 0px;
  }
  .menuItem {
    min-height: 40pt;
    padding-right: 0;
    border-bottom: 1px solid $border-color;
    .v-list-item__icon {
      height: 50pt;
      margin: 0 !important;
      font-size: 30px;
    }
    .v-list-item__title {
      font-weight: bold;
      a {
        color: $text-color;
        font-size: 14px;
        text-decoration: none;
      }
    }
    .v-list-group {
      width: 100%;
      &__items .v-list-item {
        padding-left: 16px;
      }

      .v-list-group__header {
        padding-right: 0 !important;
        min-height: 50pt;
        .v-list-item__title {
          margin-left: 0;
        }
      }
    }
    .v-list-group--active {
      .v-list-group__header {
        .v-list-item__title {
          a {
            color: var(--v-primary-base) !important;
          }
        }
      }
    }
    .v-list-item--active {
      height: 50pt;
    }
  }
}
</style>
