const routes = [
  {
    path: "/page/:pageName",
    name: "Page",
    meta: {
      site: "site"
    },
    component: () => import("@/views/wordpress/Page.vue")
  },
  {
    path: "/wp-search",
    name: "WpSearch",
    component: () => import("@/views/wordpress/Search.vue"),
    props: route => ({
      query: route.params.q,
      subtype: route.params.subtype
    }),
    meta: { slug: "search" }
  },
  {
    path: "/faq",
    meta: {
      breadCrumb: "Faq"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        name: "FaqCategory",
        path: "",
        meta: { pageName: "faq-list" },
        component: () => import("@/views/wordpress/FaqCategory.vue")
      },
      {
        path: ":categoryName",
        name: "FaqList",
        meta: {
          breadCrumb: "Dettaglio Faq"
        },
        component: () => import("@/views/wordpress/FaqList.vue")
      }
    ]
  },
  {
    path: "/form/:formId",
    name: "NinjaForms",
    meta: {
      site: "site"
    },
    component: () => import("@/commons/components/ninjaForms/NinjaForm.vue"),
    props: true
  },
  {
    path: "/we-love-trees",
    meta: {
      breadCrumb: "We Love Trees"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        name: "WeLoveTreesList",
        path: "",
        meta: { pageName: "we-love-trees" },
        component: () => import("@/views/wordpress/WeLoveTreesList.vue")
      },
      {
        path: ":postName",
        name: "WeLoveTreesDetail",
        meta: {
          breadCrumb: "Evento"
        },
        component: () => import("@/views/wordpress/WeLoveTreesDetail.vue")
      }
    ]
  },
  {
    path: "/produttori-locali",
    meta: {
      breadCrumb: "Produttori Locali"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        name: "ProduttoriList",
        path: "",
        meta: { pageName: "produttori-locali" },
        component: () => import("@/views/wordpress/ProduttoriList.vue")
      },
      {
        path: ":postName",
        name: "ProduttoriDetail",
        meta: {
          breadCrumb: "Produttore Locali"
        },
        component: () => import("@/views/wordpress/ProduttoriDetail.vue")
      }
    ]
  },
  {
    path: "/news",
    meta: {
      breadCrumb: "News"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        name: "NewsList",
        path: "",
        component: () => import("@/views/wordpress/NewsList.vue"),
        meta: { pageName: "news" },
        props: route => ({
          cat: route.query.cat,
          pageNum: route.query.page
        })
      },
      {
        path: ":postName",
        name: "NewsDetail",
        meta: {
          breadCrumb: "Dettaglio News"
        },
        component: () => import("@/views/wordpress/NewsDetail.vue")
      }
    ]
  },
  {
    path: "/ricette",
    meta: {
      breadCrumb: "Ricette",
      pageName: "ricette-wp"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        name: "RecipeList",
        path: "",
        component: () => import("@/views/wordpress/RecipeList.vue"),
        props: route => ({
          s: route.query.s,
          course: route.query.course,
          cuisine: route.query.cuisine,
          video: route.query.video,
          pageNum: route.query.page
        })
      },
      {
        path: ":postName",
        name: "RecipeDetail",
        meta: {
          breadCrumb: "Dettaglio Ricetta"
        },
        component: () => import("@/views/wordpress/RecipeDetail.vue")
      }
    ]
  }
];

export default routes;
