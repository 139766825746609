var render = function render(_c,_vm){return _c('v-card',{staticClass:"menu-banner-card mb-3",attrs:{"target":_vm.props.bannerCard.target || '_self',"href":_vm.props.bannerCard.itemUrl || '#'},on:{"click":_vm.parent.clicked}},[_c('v-img',{attrs:{"alt":_vm.parent.$ebsn.meta(
        _vm.props.bannerCard,
        'metaData.templatenavitemnavigationtype.IMAGE.alt'
      ),"src":_vm.parent.$ebsn.meta(
        _vm.props.bannerCard,
        'metaData.templatenavitemnavigationtype.IMAGE.location'
      ),"min-height":"110"}},[_c('div',{staticClass:"blurred-bg"}),_c('div',{staticClass:"text-overlay"},[(_vm.props.bannerCard.name)?_c('v-card-title',{staticClass:"white--text pb-0"},[_vm._v(_vm._s(_vm.props.bannerCard.name))]):_vm._e(),(
          _vm.parent.$ebsn.meta(
            _vm.props.bannerCard,
            'metaData.templatenavitemnavigationtype.DESCRIPTION'
          )
        )?_c('v-card-text',{staticClass:"white--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.parent.$ebsn.meta( _vm.props.bannerCard, "metaData.templatenavitemnavigationtype.DESCRIPTION" ))+" ")]):_vm._e(),(
          _vm.parent.$ebsn.meta(
            _vm.props.bannerCard,
            'metaData.templatenavitemnavigationtype.BTN_TEXT'
          )
        )?_c('v-card-actions',[_c('v-btn',{attrs:{"color":"white","depressed":""}},[_vm._v(" "+_vm._s(_vm.parent.$ebsn.meta( _vm.props.bannerCard, "metaData.templatenavitemnavigationtype.BTN_TEXT" ))+" ")])],1):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }