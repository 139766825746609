<template functional>
  <v-card
    class="menu-banner-card mb-3"
    :target="props.bannerCard.target || '_self'"
    :href="props.bannerCard.itemUrl || '#'"
    @click="parent.clicked"
  >
    <v-img
      :alt="
        parent.$ebsn.meta(
          props.bannerCard,
          'metaData.templatenavitemnavigationtype.IMAGE.alt'
        )
      "
      :src="
        parent.$ebsn.meta(
          props.bannerCard,
          'metaData.templatenavitemnavigationtype.IMAGE.location'
        )
      "
      min-height="110"
    >
      <div class="blurred-bg"></div>
      <div class="text-overlay">
        <v-card-title class="white--text pb-0" v-if="props.bannerCard.name">{{
          props.bannerCard.name
        }}</v-card-title>
        <v-card-text
          class="white--text font-weight-bold"
          v-if="
            parent.$ebsn.meta(
              props.bannerCard,
              'metaData.templatenavitemnavigationtype.DESCRIPTION'
            )
          "
        >
          {{
            parent.$ebsn.meta(
              props.bannerCard,
              "metaData.templatenavitemnavigationtype.DESCRIPTION"
            )
          }}
        </v-card-text>
        <v-card-actions
          v-if="
            parent.$ebsn.meta(
              props.bannerCard,
              'metaData.templatenavitemnavigationtype.BTN_TEXT'
            )
          "
        >
          <v-btn color="white" depressed>
            {{
              parent.$ebsn.meta(
                props.bannerCard,
                "metaData.templatenavitemnavigationtype.BTN_TEXT"
              )
            }}
          </v-btn>
        </v-card-actions>
      </div>
    </v-img>
  </v-card>
</template>
<style lang="scss">
.menu-banner-card {
  .blurred-bg:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      transparent 20%,
      rgba(0, 0, 0, 0.7) 80%
    );
  }
  .v-image {
    max-width: fit-content;
    .v-responsive__content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .text-overlay {
        position: absolute;
      }
    }
  }
}
</style>
<script>
export default {
  name: "MenuBannerCard",
  props: {
    bannerCard: { type: Object, required: true }
  }
};
</script>
