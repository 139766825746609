<template>
  <v-card class="cart-summary px-3 text-right" tile flat v-if="cart.cartId > 0">
    <v-card-text class="grey--text text--darken-1">
      <div class="d-flex justify-space-between">
        <h1>{{ $t("cartSummary.grossTotal") }}</h1>
        <h1 class="value">{{ $n(cart.totalPrice, "currency") }}</h1>
      </div>
      <div
        class="d-flex justify-space-between align-top font-weight-bold text-body-2"
      >
        <span>
          <span>{{ $t("cartSummary.totalPriceVariable") }}</span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="secondary" class="ml-3" v-bind="attrs" v-on="on"
                >$info</v-icon
              >
            </template>
            <!-- TODO: che tooltip? -->
            <span>TBD</span>
          </v-tooltip>
        </span>
        <span class="value">{{ $n(cart.totalPriceVariable, "currency") }}</span>
      </div>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-row no-gutters>
        <v-col cols="6" class="pa-1">
          <v-btn
            :to="{ name: 'Cart' }"
            outlined
            color="primary"
            class="rounded-xl"
            x-large
            block
            depressed
            @click="closeDrawer"
          >
            <span class="secondary--text">{{
              $t("cart.button.goToCart")
            }}</span>
          </v-btn>
        </v-col>
        <v-col cols="6" class="pa-1" v-if="cart.totalItems > 0">
          <v-btn
            :to="{ name: 'Checkout' }"
            color="primary"
            class="primary secondary--text rounded-xl"
            x-large
            block
            depressed
            @click="closeDrawer"
            >{{ $t("cart.button.goToPayment") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
  <v-card v-else>{{ $t("no-cart") }}</v-card>
</template>
<style lang="scss">
.cart-summary {
  h1 {
    font-size: 25px;
    line-height: 40px;
    text-transform: uppercase;
  }
  .value {
    min-width: 150px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .v-card__actions {
      padding: 8px 0;
    }
  }
}
</style>
<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "CartSummary",
  props: ["full"],
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    })
  },
  methods: {
    ...mapActions({
      emptyCart: "cart/emptyCart",
      updatedDrawerRight: "app/updatedDrawerRight",
      removeGiftCode: "cart/removeGiftCode"
    }),
    async emptyCartConfirm() {
      if (this.cart.totalItems > 0) {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.emptyCart")
        });
        if (res) {
          this.emptyCart();
        }
      }
    },
    closeDrawer() {
      this.updatedDrawerRight(false);
    },
    removeCode(giftCodeId) {
      this.removeGiftCode(giftCodeId);
    },
    changeService() {},
    changeTimeslot() {},
    changeDelivery() {}
  }
};
</script>
