let routes = [
  {
    path: "/premiali",
    meta: {
      breadCrumb: "Premialí"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        name: "Awards",
        component: () => import("@/views/profile/awards/AwardsList.vue"),
        meta: { breadCrumb: "Premialí", slug: ["awards"] }
      },
      {
        path: ":slug",
        name: "AwardsDetail",
        meta: {
          breadCrumb: "Dettaglio Premio"
        },
        component: () => import("@/views/profile/awards/AwardsDetail.vue")
      }
    ]
  },
  {
    path: "/reset-password-request",
    name: "ResetPasswordRequest",
    component: () => import("@/views/profile/ResetPasswordRequest.vue"),
    meta: {
      slug: "reset-password-request"
    }
  },
  {
    path: "/reset-password-confirm",
    name: "ResetPasswordConfirm",
    props: route => ({
      message: route.params.message,
      login: route.params.login || route.query?.login,
      queryToken: route.query?.token
    }),
    component: () => import("@/views/profile/ResetPasswordConfirm.vue"),
    meta: { slug: "reset-password-confirm" }
  },
  {
    path: "/recover-password-confirm",
    name: "ResetPassword",
    component: () => import("@/views/profile/ResetPassword.vue"),
    meta: { slug: "reset-password" }
  },
  {
    path: "/reset-password-completed",
    name: "ResetPasswordCompleted",
    component: () => import("@/views/EbsnCategory.vue"),
    props: { showLogin: true },
    meta: { slug: "reset-password-completed" }
  },
  {
    path: "/registration-user",
    name: "RegistrationUser",
    component: () => import("@/views/profile/RegistrationUser.vue"),
    meta: { slug: "registration-choose" }
  },
  {
    path: "/registration-card",
    name: "RegistrationCard",
    component: () => import("@/views/profile/RegistrationForms.vue"),
    props: {
      type: "OldUser",
      isRegisterWithCard: true
    },
    meta: { slug: "registration-card" }
  },
  {
    path: "/registration-nocard",
    name: "RegistrationNoCard",
    component: () => import("@/views/profile/RegistrationForms.vue"),
    props: {
      type: "NewUser",
      isRegisterWithCard: false
    },
    meta: { slug: "registration-nocard" }
  },
  {
    path: "/thankyou-agreement",
    name: "RegistrationCompletedAgreement",
    component: () => import("@/views/profile/RegistrationCompleted.vue"),
    props: {
      mail: null,
      agreement: true
    },
    meta: { slug: "registration-confirm-agreement" }
  },
  {
    path: "/registration/confirm",
    name: "RegistrationConfirmed",
    component: () => import("@/views/profile/RegistrationConfirmed.vue"),
    meta: { slug: "registration-confirmed" }
  },
  {
    path: "/registration/thankyou",
    name: "RegistrationCompleted",
    component: () => import("@/views/profile/RegistrationCompleted.vue"),
    props: {
      mail: null,
      agreement: true
    },
    meta: { slug: ["registration/thankyou", "registration-confirm"] }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { requiresAuth: true, breadCrumb: "Bacheca", slug: "dashboard" },
    component: () => import("@/views/profile/Dashboard.vue")
  },
  {
    path: "/profile",
    name: "Profile",
    meta: {
      requiresAuth: true,
      breadCrumb: "Area personale",
      slug: "dashboard"
    },
    component: () => import("@/views/profile/Profile.vue"),
    children: [
      {
        path: "gifts",
        name: "ProfileGift",
        meta: {
          requiresAuth: true,
          breadCrumb: "Buoni sconto",
          slug: ["vouchers"]
        },
        component: () => import("@/views/profile/ProfileGift.vue")
      },
      {
        path: "crea",
        name: "ProfileGift",
        meta: {
          requiresAuth: true,
          breadCrumb: "Crea la tua offerta",
          slug: ["crea"]
        },
        component: () => import("@/views/profile/ProfileGift.vue")
      },
      {
        path: "profile-update",
        name: "ProfileUpdate",
        props: route => ({
          emailchanged: route.query.emailchanged
        }),
        meta: {
          requiresAuth: true,
          breadCrumb: "Il mio profilo",
          slug: ["profile"]
        },
        component: () => import("@/views/profile/ProfileUpdate.vue")
      },
      {
        path: "profile-update-error",
        name: "ProfileUpdateError",
        meta: {
          requiresAuth: true,
          breadCrumb: "I miei dati",
          slug: ["profile-error", "profile/profile-update-error"]
        },
        component: () => import("@/views/profile/ProfileUpdateError.vue")
      },
      {
        path: "profile-confirmed",
        name: "ProfileConfirmed",
        component: () => import("@/views/profile/ProfileConfirmed.vue"),
        meta: {
          requiresAuth: true,
          breadCrumb: "Aggiorna password",
          slug: ["profile-confirmed", "profile/profile-confirmed"]
        }
      },
      {
        path: "profile-updated",
        name: "ProfileUpdated",
        component: () => import("@/views/EbsnCategory.vue"),
        meta: {
          requiresAuth: true,
          breadCrumb: "Aggiorna password",
          slug: ["profile-updated", "profile/profile-updated"]
        }
      },
      {
        path: "email-updated",
        name: "EmailUpdated",
        component: () => import("@/views/EbsnCategory.vue"),
        meta: { slug: ["profile/email-updated", "email-updated"] }
      },
      {
        path: "invite",
        name: "Invite",
        meta: {
          requiresAuth: true,
          breadCrumb: "Invita i tuoi amici",
          slug: ["profile/invite", "invite"]
        },
        component: () => import("@/views/profile/Invite.vue")
      },

      {
        path: "orders",
        meta: {
          requiresAuth: true,
          breadCrumb: "I miei ordini",
          slug: ["orders"]
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        beforeEnter(to, from, next) {
          global.EventBus.$emit("resetFilters");
          next();
        },
        children: [
          {
            name: "Orders",
            path: "",
            meta: {
              requiresAuth: true,
              slug: ["orders"]
            },
            component: () => import("@/views/profile/Orders.vue")
          },
          {
            name: "Order",
            path: ":orderId",
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio ordine",
              meta: ["order", "profile/orders/order"]
            },
            component: () => import("@/views/profile/Order.vue")
          }
        ]
      },
      {
        path: "lists",
        meta: {
          requiresAuth: true,
          breadCrumb: "Le tue liste"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        beforeEnter(to, from, next) {
          global.EventBus.$emit("resetFilters");
          next();
        },
        children: [
          {
            name: "Lists",
            path: "",
            meta: {
              requiresAuth: true,
              meta: ["profile/lists", "lists"],
              slug: "lists"
            },
            component: () => import("@/views/profile/Lists.vue")
          },
          {
            path: ":listId",
            name: "List",
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio lista"
            },
            component: () => import("@/views/profile/List.vue")
          }
        ]
      },

      {
        path: "addresses",
        meta: { requiresAuth: true, breadCrumb: "I miei indirizzi" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "Addresses",
            meta: {
              requiresAuth: true,
              slug: ["profile/addresses"]
            },
            component: () => import("@/views/profile/ProfileAddresses.vue")
          },
          {
            path: "manage/:addressId?",
            name: "EditAddress",

            meta: { requiresAuth: true, breadCrumb: "Dettaglio indirizzo" },
            component: () => import("@/views/profile/EditAddress.vue")
          }
        ]
      },
      {
        path: "payment-method",
        name: "MyPaymentMethods",
        meta: {
          requiresAuth: true,
          breadCrumb: "Le mie carte",
          slug: ["profile/payment-method", "payment-method"]
        },
        component: () => import("@/views/profile/MyPaymentMethods.vue")
      },
      // {
      //   path: "last-purchase",
      //   name: "LastPurchase",
      //   meta: {
      //     requiresAuth: true,
      //     breadCrumb: "Ultima spesa",
      //     slug: ["profile/last-purchase", "last-purchase", "lastorder"]
      //   },
      //   component: () => import("@/views/profile/LastPurchase.vue")
      // },
      {
        path: "rewards-cart",
        name: "RewardsCart",
        meta: { requiresAuth: true, breadCrumb: "Carrello premi" },
        component: () => import("@/views/profile/RewardsCart.vue")
      },
      {
        path: "requested-rewards",
        name: "RequestedRewards",
        meta: { requiresAuth: true, breadCrumb: "Premi richiesti" },
        component: () => import("@/views/profile/RequestedRewards.vue")
      },
      {
        path: "create-offer",
        name: "CreateOffer",
        meta: { requiresAuth: true, breadCrumb: "Crea la tua offerta" },

        component: {
          render(c) {
            return c("router-view");
          }
        },

        children: [
          {
            path: "",
            name: "CreateOffer",
            component: () => import("@/views/profile/CreateOffer.vue"),
            meta: { slug: ["create-offer"] }
          }
        ]
      },
      {
        // booking/:listId/:storeId
        path: "promo/:listId",
        name: "PromoPersonal",
        props: route => ({
          storeId: route.query.storeId
        }),
        component: () => import("@/views/profile/PromoPersonal.vue"),
        meta: { breadCrumb: "Lista prenotazione", slug: ["promo"] }
      },
      {
        path: "donate",
        name: "Donate",
        meta: { requiresAuth: true, breadCrumb: "Dona i tuoi punti" },
        component: () => import("@/views/profile/Donate.vue")
      },
      // {
      //   // booking/:listId/:storeId
      //   path: "booking/:listId",
      //   name: "Booking",
      //   props: route => ({
      //     storeId: route.query.storeId
      //   }),
      //   component: () => import("@/views/profile/awards/AwardsCart.vue"),
      //   meta: { breadCrumb: "Lista prenotazione", slug: ["booking"] }
      // },
      {
        path: "booking-completed",
        name: "BookingCompleted",
        props: true,
        component: () =>
          import("@/views/profile/awards/AwardsCartCompleted.vue"),
        meta: {
          breadCrumb: "Prenotazione premi completata",
          slug: ["booking-completed"]
        }
      },
      {
        path: "booked",
        name: "BookedAwards",
        component: () => import("@/views/profile/awards/BookedAwards.vue"),
        meta: { breadCrumb: "Prenotazione premi", slug: ["booked-awards"] }
      }
    ]
  },
  {
    path: "/points-donation-confirmed",
    name: "ConfirmPointsDonation",
    meta: {
      breadCrumb: "Conferma donazione punti",
      slug: "points-donation-confirmed"
    },
    component: () => import("@/views/profile/ConfirmPointsDonation.vue")
  }
];

export default routes;
