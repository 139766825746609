import base from "./base";
import wordpress from "./wordpress";
import catalog from "./catalog";
import profile from "./profile";
import checkout from "./checkout";

const routes = [
  {
    path: "/",
    name: "HomeSite",
    meta: {
      site: "site",
      slug: "home-site",
      canonical: "/"
    },
    component: () => import("@/views/catalog/Category.vue")
  },
  {
    path: "/aliperme",
    name: "Home",
    props: true,
    meta: {
      site: "ecommerce",
      slug: "aliperme"
    },
    component: () => import("@/views/catalog/Category.vue")
  },
  {
    path: "/app",
    name: "HomeApp",
    props: true,
    meta: {
      site: "app",
      slug: "homepage-app"
    },
    component: () => import("@/views/catalog/Category.vue")
  },
  ...base,
  ...wordpress,
  ...profile,
  ...checkout,
  ...catalog
];

export default routes;
