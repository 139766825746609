<template>
  <v-row no-gutters class="primary" align="center">
    <v-col cols="3" class="d-flex justify-start mr-5">
      <v-img
        eager
        src="/logo/aliperme.svg"
        height="39"
        max-width="147"
        contain
        position="left center"
      />
    </v-col>
    <v-col cols="4" class="d-flex align-center right-border">
      <div
        :disabled="!editableTimeslot"
        @click="openTimeslotSelector"
        class="d-flex flex-column justify-start w-100"
      >
        <!-- <div class="pb-0 text-uppercase font-weight-bold">
          {{ $t("cart.cartInfo.timeslot") }}
        </div>
        <div class="info-text">{{ timeslot }}</div> -->

        <div class="pb-0 text-uppercase font-weight-bold">
          {{ $t("cart.cartInfo.timeslot") }}
        </div>
        <div class="info-text">{{ timeslot }}</div>
      </div>
    </v-col>
    <v-col cols="4" class="d-flex align-center">
      <div
        :disabled="!editableAddress"
        @click="openAddressSelector"
        class="d-flex flex-column justify-end w-100"
      >
        <div class="pb-0 text-uppercase font-weight-bold">
          {{ $t("cart.cartInfo.editableAddress") }}
        </div>
        <div class="info-text">{{ shippingAddress }}</div>
      </div>
    </v-col>
  </v-row>
</template>
<style lang="scss" scoped>
.info-text {
  max-width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.right-border {
  border-right: 1px solid $secondary;
}
</style>
<script>
import { mapState } from "vuex";
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
export default {
  name: "MiniCartInfoList",
  mixins: [login, cartInfo],
  data() {
    return {
      maxWidth: 320,
      maxHeight: 60
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    })
  }
};
</script>
