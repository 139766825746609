<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      v-if="
        isECommerce &&
          isParkingEnabled &&
          (isCordova || $vuetify.breakpoint.xsOnly)
      "
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <v-fab-transition>
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            fixed
            right
            bottom
            color="primary-light"
            class="reserve-parking mb-1"
          >
            <v-img
              src="/img_layout/icon-parcheggio-wh.png"
              contain
              height="30"
              width="30"
            />
            <span class="reserve-parking-label white--text">
              Ritiro <br />
              in parcheggio
            </span>
          </v-btn>
        </v-fab-transition>
      </template>

      <div class="d-flex flex-column reserve-parking-container" v-if="dialog">
        <v-btn @click.stop="dialog = false" icon class="close">
          <v-icon>$close</v-icon>
        </v-btn>
        <ReserveParking
          :key="order.orderId"
          :order="order"
          :reserveParkingSuccess="reserveParkingSuccess"
          :alternativesList="alternativesList"
          :error="error"
          :message="message"
          :description="description"
        />
      </div>
    </v-dialog>
  </div>
</template>
<style lang="scss">
.reserve-parking {
  bottom: 105px;
  padding: 41px;
  left: 2%;
  background-color: var(--v-primary-base) !important;
  color: #000 !important;
  .description {
    display: inline;
  }
  .v-btn__content {
    flex-direction: column;
    display: flex;
    white-space: nowrap;
    margin-top: -12px;
  }
  .reserve-parking-label {
    font-size: 9px;
    margin-top: 0px !important;
  }
}
.reserve-parking-container {
  position: relative;
}
.close {
  position: absolute;
  top: 0px;
  right: 0px;
  color: #000;
  border: none !important;
}
.reserve-btn {
  color: $white;
  background-color: var(--v-primary-lighten1);
  border-radius: 25px;
  padding: 24px !important;
  font-size: 14px;
}
.is-cordova {
  .reserve-parking {
    bottom: 100px;
  }
}

.is-cordova.keyboard-open {
  .reserve-parking {
    bottom: 15px;
  }
}

.is-cordova.keyboard-open.platform-ios {
  .reserve-parking {
    bottom: 30px;
  }
}
.is-cordova.platform-ios {
  .reserve-parking {
    bottom: 130px;
  }
}
</style>
<script>
import ReserveParking from "@/components/ReserveParking.vue";

import OrderService from "~/service/orderService";
import category from "~/mixins/category";

import { mapGetters } from "vuex";

export default {
  mixins: [category],

  data() {
    return {
      userLoggedIn: false,
      dialog: false,
      orders: [],
      error: null,
      message: null,
      description: null,
      reserveParkingSuccess: false,
      // orderStatusList: [6, 18],
      orderStatusList: [3],
      alternativesList: [],
      isParkingEnabled: false
    };
  },
  components: { ReserveParking },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    isECommerce() {
      return this.category.slug == "aliperme";
    }
  },
  mounted() {
    global.EventBus.$on("parkReserved", () => {});
    global.EventBus.$on("parkNotReserved", () => {});
    global.EventBus.$on("reservingParkError", () => {});
    global.EventBus.$on("closeDialog", () => {
      this.dialog = false;
    });
    this.fetchOrders();
  },
  methods: {
    async fetchOrders() {
      let data = await OrderService.getOrders(
        this.pageFilter,
        process.env.VUE_APP_ORDERS_LIST_PAGE_SIZE,
        false
      );
      if (data.orders.length > 0) {
        for (var i = 0; i < data.orders.length; i++) {
          if (data.orders[i].isParkingEnabled) {
            this.order = data.orders[i];
            this.isParkingEnabled = true;
            break;
          }
        }
      }
    }
  }
};
</script>
