<template>
  <div class="cart-item">
    <v-list-item
      :to="{
        name: 'Product',
        params: { slug: item.product.slug }
      }"
      :class="isCheckout ? 'py-3 is-checkout' : 'py-2 is-not-checkout'"
    >
      <v-row class="w-100 my-2 my-sm-0" align="center" justify="space-between">
        <v-col cols="12" :sm="isCheckout ? 5 : 6" class="pa-0 d-flex">
          <v-img
            contain
            max-width="70px"
            :src="item.product.mediaURL"
            :alt="item.product.name"
            onerror="this.onerror=null;this.src='/no-icon.png'"
          />

          <v-list-item-content class="default--text pa-0 pa-sm-2">
            <div class="font-weight-bold text-body-2">
              {{ item.product.name }}
            </div>
            <v-list-item-subtitle class="text-uppercase text-body-2">
              {{ item.product.shortDescr }}
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="item.cartItemInfo && item.cartItemInfo.selected == true"
            >
              <div
                class="text-uppercase text-body-3 rounded-sm promo1 white--text pa-1"
                style="width:fit-content"
              >
                CREA LA TUA OFFERTA
              </div>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="item.cartItemInfo.size"
              class="cart-item-info"
            >
              <i>Preferenza: {{ item.cartItemInfo.size }}</i>
            </v-list-item-subtitle>

            <v-list-item-subtitle
              v-if="
                itemAdjustment &&
                  itemAdjustment.userGiftCertificateId &&
                  itemAdjustment.giftCertificate
              "
              class="cart-item-info promo--text"
            >
              <em>{{ itemAdjustment.giftCertificate.name }}: </em>
              <!-- -{{ $n(itemAdjustment.valueVariation, "currency") }} -->
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-col>
        <v-col cols="12" sm="6" lg="5" class="pa-0">
          <v-list-item-action class="mr-0 w-100 mt-3 mt-sm-0 mb-0">
            <div
              v-if="item.editable"
              class="d-flex align-center w-100"
              :class="addClass"
            >
              <ProductPrice
                v-if="isCheckout"
                :product="item.product"
                :isCheckout="isCheckout"
              ></ProductPrice>
              <ProductQty
                :item="item"
                :product="item.product"
                :showCartButton="showCartButton"
                :updateCart="true"
              />

              <div
                class="price flex-grow-1 text-center font-weight-bold"
                :class="isCheckout ? '' : 'ml-2'"
              >
                {{ $n(item.grossTotal, "currency") }}
              </div>
              <v-btn
                icon
                aria-label="Rimuovi dal carrello"
                @click.prevent="remove"
                @mousedown.stop
                color="primary"
                v-if="
                  !(
                    item.product.productInfos &&
                    item.product.productInfos.USER_DISABLED == '1'
                  ) && !isCheckout
                "
              >
                <v-icon color="secondary">$delete</v-icon>
              </v-btn>
            </div>
          </v-list-item-action>
        </v-col>
        <v-col class="text-right" cols="12" sm="1" v-if="isCheckout">
          <v-btn
            :outlined="$vuetify.breakpoint.xsOnly"
            class="elevation-0 default--text w-100"
            :icon="$vuetify.breakpoint.smAndUp"
            aria-label="Rimuovi dal carrello"
            @click.prevent="remove"
            @mousedown.stop
            color="primary"
            v-if="
              !(
                item.product.productInfos &&
                item.product.productInfos.USER_DISABLED == '1'
              )
            "
          >
            <v-icon v-if="$vuetify.breakpoint.smAndUp" color="secondary"
              >$delete</v-icon
            >
            <span v-else class="text-uppercase">{{
              $t("product.removeProduct")
            }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-list-item>
    <div v-if="isCheckout && isHomeDelivery" class="my-1 mx-3">
      <v-checkbox
        dense
        color="primary"
        hide-details
        class="accept-alternatives pt-0"
        v-model="acceptAlternative"
      >
        <template v-slot:label>
          <span>{{ $t("cart.cartDetail.acceptAlternative") }}</span>
        </template>
      </v-checkbox>
      <div v-if="acceptAlternative" class="mt-1">
        <v-textarea
          v-if="isCheckout"
          ref="note"
          :label="$t('cart.cartDetail.note')"
          auto-grow
          dense
          solo
          flat
          hide-details="auto"
          rows="1"
          row-height="15"
          prepend-inner-icon="$edit"
          v-model="userNotes"
          @blur="updateNote(item, 'user_notes', tempUserNotes)"
          class="note-textarea"
        ></v-textarea>
      </div>
    </div>
    <v-divider></v-divider>
    <v-dialog v-model="removeDialog" max-width="400">
      <!-- <div class="vuedl-layout__closeBtn" @click="removeDialog = false">
        ×
      </div> -->
      <v-card>
        <v-card-text class="body-1 pt-8">
          {{
            $t("message.remove", {
              name: this.product.name,
              quantity: this.quantity,
              unit: this.unit
            })
          }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn outlined text @click="removeDialog = false">
            No
          </v-btn>

          <v-btn
            class="primary white--text"
            text
            @click.prevent="doRemove"
            @mousedown.stop
          >
            Si
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss">
.cart-item {
  .product-promo-price {
    min-width: 140px;
    .cur-price {
      text-align: center;
    }
  }
  .price {
    min-width: 60px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      min-width: auto !important;
    }
    font-size: 20px;
  }
  .is-not-checkout {
    .price {
      font-size: 18px;
    }
  }

  .product-price {
    min-width: 141px;
    text-align: center;
    justify-content: space-around;
    &.product_promo {
      min-width: 141px;
      @media #{map-get($display-breakpoints, 'xs-only')} {
        min-width: auto !important;
      }
      margin: 0 5px;
      .bubble,
      .card {
        display: none;
      }
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      min-width: auto !important;
    }
    .cur-price {
      @media (max-width: 320px) {
        font-size: 20px;
        margin-top: -5px;
      }
    }
  }
  .qty-wrap-cnt {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      justify-content: end;
    }
  }
  .qty-wrap {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      min-width: auto !important;
      max-width: auto !important;
    }
  }
  .gross-total {
    width: auto !important;
    min-width: 70px;
    text-align: right;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      min-width: auto !important;
      width: 25%;
      font-size: 18px !important;
    }
  }
  .note-textarea {
    .v-input__slot {
      padding: 0 !important;
    }
    .v-input__control .v-input__slot {
      background-color: transparent !important;
    }
    .v-icon.v-icon {
      font-size: 16px;
    }
  }
}
</style>
<script>
import ProductPrice from "@/components/product/ProductPrice.vue";
import ProductQty from "@/components/product/ProductQty.vue";
import productMixin from "~/mixins/product";
import get from "lodash/get";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "CartItem",
  components: {
    ProductPrice,
    ProductQty
  },
  props: {
    full: { type: Boolean, default: false },
    isCheckout: { type: Boolean, default: false },
    showCartButton: { type: Boolean, default: false },
    item: { type: Object, required: true },
    isHomeDelivery: { type: Boolean, default: false }
  },
  mixins: [productMixin],
  data() {
    return { removeDialog: false, tempUserNotes: null };
  },
  computed: {
    product() {
      return this.item.product;
    },
    /**
     *  :class="
                isCheckout || $vuetify.breakpoint.xs
                  ? 'justify-space-between'
                  : 'justify-end'
              "
     */
    // if is checkout or xs jsb else je
    //  if is checkout and smOnly jsb fw else je
    addClass() {
      let cssClass = "";
      if (this.isCheckout && this.$vuetify.breakpoint.xs) {
        cssClass = "justify-space-between";
      } else if (this.isCheckout && this.$vuetify.breakpoint.smOnly) {
        cssClass = "justify-space-between";
      } else {
        cssClass = "justify-end";
      }
      return cssClass;
    },
    classCardContainerObject: function() {
      if (this.isCheckout && this.$vuetify.breakpoint.mdAndUp) {
        return "isCheckout d-flex flex-column justify-space-around";
      } else {
        if (!this.$vuetify.breakpoint.xs) {
          return "d-flex flex-row justify-space-around";
        }
        return "XS";
      }
    },
    itemAdjustment() {
      return this.getOrderAdjustmentByItemId(this.item.itemId);
    },
    ...mapGetters({
      getOrderAdjustmentByItemId: "cart/getOrderAdjustmentByItemId"
    }),
    acceptAlternative: {
      get() {
        return (
          get(this.item, "cartItemInfo.accept_alternatives", "true") == "true"
        );
      },
      set(value) {
        this.setCartItemInfo({
          items: this.item,
          name: "accept_alternatives",
          value: "" + value
        });
      }
    },
    userNotes: {
      get() {
        return this.item.cartItemInfo.user_notes;
      },
      set(value) {
        this.tempUserNotes = value;
      }
    }
  },
  methods: {
    remove() {
      this.removeDialog = true;
    },
    doRemove() {
      this.removeDialog = false;
      this.removeItem(this.item);
    },
    ...mapActions({
      setCartItemInfo: "cart/setCartItemInfo"
    }),
    updateNote(item, name, value) {
      if (value != null) {
        this.setCartItemInfo({
          items: item,
          name: name,
          value: "" + value
        });
      }
    }
  }
};
</script>
