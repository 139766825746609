<template>
  <v-container class="pa-0 white menu-link-list-container">
    <div>
      <v-list class="info-tab px-2">
        <v-list-item
          v-for="link in infoLinks"
          :key="link.templateNavItemId"
          class="link accent"
          :href="link.itemUrl"
          @click="clicked"
        >
          <v-list-item-icon>
            <v-icon color="default"> {{ link.iconSource }} </v-icon>
          </v-list-item-icon>
          <v-list-item-title :class="link.classStyle" class="text--bold">
            {{ link.name }}
          </v-list-item-title>
        </v-list-item>
        <template v-for="item in menuLinks">
          <v-list-item
            v-if="item.children?.length <= 0"
            :key="item.templateNavItemId"
            class="menuItem"
            :href="item.itemUrl"
            @click="onLinkClick"
          >
            <v-list-item-title class="ml-0" :class="item.cssClass">
              {{ item.name }}
            </v-list-item-title>
          </v-list-item>
          <v-list-group
            class="menuItem"
            v-else
            :key="item.templateNavItemId"
            :class="
              item.itemUrl && item.itemUrl.length > 0
                ? 'menuItem link-enabled'
                : 'menuItem link-disabled'
            "
          >
            <template v-slot:activator>
              <v-list-item-title>
                <a
                  v-if="item.itemUrl && item.itemUrl.length > 0"
                  :href="item.itemUrl"
                  event
                  @click="onLinkClick"
                >
                  {{ item.name }}</a
                >
                <span v-else>{{ item.name }}</span>
              </v-list-item-title>
            </template>
            <div
              v-for="child in filteredExcludedNavItemNavigationType(
                item.templateNavItems,
                [3]
              )"
              :key="child.templateNavItemId"
            >
              <v-list-item link :href="child.itemUrl" @click="clicked">
                <v-list-item-title class="ml-1">
                  {{ child.name }}
                </v-list-item-title>
              </v-list-item>
              <v-list class="py-0" dense v-if="child.templateNavItems?.length">
                <v-list-item
                  v-for="child2 in child.templateNavItems"
                  :key="child2.templateNavItemId"
                  link
                  :href="child2.itemUrl || '#'"
                  @click="clicked"
                  class="text-caption default--text ml-3"
                  :class="child2.cssClass"
                >
                  {{ child2.name }}
                </v-list-item>
              </v-list>
            </div>
            <MenuBannerCard
              v-for="navItemBanner in filteredIncludedNavItemNavigationType(
                item.templateNavItems,
                [3]
              )"
              :key="navItemBanner.templateNavItemId"
              :bannerCard="navItemBanner"
            />
          </v-list-group>
        </template>
      </v-list>
    </div>
    <div class="d-flex justify-space-between align-center my-5 pl-4">
      <div class="text-body-2 font-weight-bold default--text text-uppercase">
        {{ $t("links.title") }}
      </div>
    </div>
    <div>
      <!-- <div no-gutters v-for="(link, index) in links" v-bind:key="index">
        <a
          :href="link.itemUrl"
          @click="onLinkClick"
          class="text-body-2 text-uppercase default--text text-decoration-none pl-4 font-weight-bold"
          >{{ link.name }}</a
        >
        <p
          v-for="(child, childIdx) in link.templateNavItems"
          :key="childIdx"
          class="my-1 child pl-4"
        >
          <a
            :href="child.itemUrl"
            @click="onLinkClick"
            :target="child.target"
            class="text-body-2 default--text text-decoration-none"
          >
            <v-img
              v-if="child.classStyle == 'appstore-bg' && !isCordova"
              src="/img_layout/footer/app-store.png"
              contain
              position="initial"
              :max-height="$vuetify.breakpoint.sm ? 35 : 45"
              alt="logo App Store"
            />
            <v-img
              v-else-if="child.classStyle == 'gplay-bg' && !isCordova"
              src="/img_layout/footer/google-play.png"
              contain
              position="initial"
              :max-height="$vuetify.breakpoint.sm ? 35 : 45"
              alt="logo Google Play"
            />
            <span :class="child.classStyle" v-else>{{ child.name }}</span></a
          >
        </p>
      </div> -->
      <div v-if="links && $vuetify.breakpoint.smAndDown">
        <v-expansion-panels accordion flat tile :focusable="selectedTab">
          <v-expansion-panel v-for="(link, index) in links" :key="index">
            <v-expansion-panel-header>
              <template v-slot:actions>
                <v-icon>$chevronDown</v-icon>
              </template>
              {{ link.name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mt-2">
              <a
                v-for="child in link.templateNavItems"
                :key="child.ID"
                :href="child.itemUrl"
                class="d-block default--text text-decoration-none pa-1"
                :class="[child.classStyle, { 'no-evt': index == 2 }]"
                :target="child.target"
                @click="clicked"
              >
                <span :class="child.classStyle">{{ child.name }}</span>
              </a>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div
        id="social-network-wrapper"
        class="primary d-flex justify-center align-center w-100 pa-2 flex-column"
      >
        <span class="text-body-2 font-weight-bold mr-2"
          >{{ $t("footer.followUs") }}:</span
        >
        <div class="d-flex flex-row justify-center align-center w-100 mt-4">
          <div
            v-for="(item, idx) in socialLinks"
            :key="idx"
            class="d-flex align-center"
          >
            <a :href="item.itemUrl" target="_blank">
              <v-icon class="mx-2">{{ `$${item.iconSource}` }}</v-icon>
            </a>
          </div>
        </div>
      </div>
      <div>
        <div class="my-2 d-flex justify-center">
          <RecaptchaDisclaimer @click="clicked" />
        </div>
      </div>
    </div>
    <div
      class="section default--text last justify-center d-flex"
      v-if="isCordova"
    >
      <i18n path="footer.version" tag="span" class="my-1 child pl-4">
        {{ version }}
      </i18n>
    </div>
  </v-container>
</template>
<style lang="scss">
.menu-link-list-container {
  .v-expansion-panel-header {
    font-size: 14px;
  }
  .section {
    border-bottom: 1px solid #e9e9e9;
    padding: 12px 0;
    &:first-of-type {
      padding-top: 0 !important;
    }
  }
  .section.last {
    border-bottom: none;
  }
}
.menuItem:has(div.v-list-group) {
  padding-left: 0px;
}
.link-enabled {
  .v-list-item--link {
    height: 50pt;
  }
  .v-list-item__icon {
    display: none;
  }
}
.menuItem {
  min-height: 40pt;
  padding-right: 0;
  border-bottom: 1px solid $border-color;
  .v-list-item__icon {
    height: 50pt;
    margin: 0 !important;
    font-size: 30px;
  }
  .v-list-item__title {
    font-weight: bold;
    a {
      color: $text-color;
      font-size: 14px;
      text-decoration: none;
    }
  }
  .v-list-group {
    width: 100%;
    &__items .v-list-item {
      padding-left: 16px;
    }

    .v-list-group__header {
      padding-right: 0 !important;
      min-height: 50pt;
      .v-list-item__title {
        margin-left: 0;
      }
    }
  }
  .v-list-group--active {
    .v-list-group__header {
      .v-list-item__title {
        a {
          color: var(--v-primary-base) !important;
        }
      }
    }
  }
  .v-list-item--active {
    height: 50pt;
  }
}
</style>
<script>
import RecaptchaDisclaimer from "~/components/RecaptchaDisclaimer.vue";
import MenuBannerCard from "@/components/navigation/MenuBannerCard.vue";
import clickHandler from "~/mixins/clickHandler";
import downloadDocument from "~/mixins/downloadDocument";
import page from "~/mixins/page";
export default {
  mixins: [clickHandler, downloadDocument, page],
  props: ["links", "socialLinks", "infoLinks", "menuLinks"],
  components: { RecaptchaDisclaimer, MenuBannerCard },
  data() {
    return {
      selectedTab: null
    };
  },
  computed: {
    version() {
      return global.config.version;
    }
  },
  methods: {
    isSocialLinksColumn(classes) {
      return classes.search("social") > -1;
    },
    isCordovaPlatform(classes) {
      return this.isSocialLinksColumn(classes) && this.cordovaPlatform;
    },
    onLinkClick(e) {
      var url = e.currentTarget.href;
      let fileName = "";
      this.$emit("close");
      this.$nextTick(() => {
        console.log("EVENT:", e);
        if (
          this.$platform_is_cordova &&
          e.currentTarget.href.endsWith(".pdf")
        ) {
          fileName = url.substring(url.lastIndexOf("/") + 1, url.length);
          fileName = fileName.substring(0, fileName.length - 4);
          this.handleReceiptDownload(url, fileName);
        } // else if (e.target.className.includes("cookiebot-widget")) {
        //   if (window.Cookiebot) {
        //     window.Cookiebot.show();
        //   }
        else if (e.target.className.includes("cookiebot-widget")) {
          if (window.Cookiebot) {
            window.Cookiebot.show();
          }
        } else {
          this.clicked(e);
        }
      });
    },
    filteredIncludedNavItemNavigationType(navItems, type = [3]) {
      return navItems.filter(
        navItem =>
          navItem.templateNavItemNavigationType &&
          navItem.templateNavItemNavigationType
            .templateNavItemNavigationTypeId &&
          type.includes(
            navItem.templateNavItemNavigationType
              .templateNavItemNavigationTypeId
          )
      );
    },
    filteredExcludedNavItemNavigationType(navItems, type = [3]) {
      return navItems.filter(
        navItem =>
          !navItem.templateNavItemNavigationType ||
          !navItem.templateNavItemNavigationType
            .templateNavItemNavigationTypeId ||
          !type.includes(
            navItem.templateNavItemNavigationType
              .templateNavItemNavigationTypeId
          )
      );
    }
  }
};
</script>
