var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 category-tree-container"},[_c('div',{staticClass:"header d-flex d-md-none flex-column"},[_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-btn',{staticClass:"white",attrs:{"depressed":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',{attrs:{"large":!_vm.isCordova,"medium":_vm.isCordova}},[_vm._v("$close")])],1)],1),_c('v-tabs',{staticClass:"px-1 mb-4",attrs:{"hide-slider":"","grow":"","active-class":"activeTab"},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab',{key:"categories"},[_c('v-img',{attrs:{"eager":"","contain":"","src":"/favicon.png","height":"24","max-width":"32"}}),_c('h3',{staticClass:"text-none"},[_vm._v(_vm._s(_vm.$t("navbar.products")))])],1),(_vm.$vuetify.breakpoint.smAndDown && !_vm.isCordova)?_c('v-tab',{key:"info"},[_c('h3',{staticClass:"text-none"},[_vm._v(_vm._s(_vm.$t("navbar.links")))])]):_vm._e()],1)],1),_c('v-tabs-items',{on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab-item',{key:"categories"},[_c('v-list',{staticClass:"category-tree px-2 px-sm-0"},_vm._l((_vm.categories),function(category){return _c('v-list-group',{key:category.categoryId,staticClass:"category1",scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',{staticClass:"font-weight-bold"},[_c('router-link',{attrs:{"to":{
                  name: 'Category',
                  params: { pathMatch: category.slug }
                }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.goToCategory(category)}}},[_vm._v(" "+_vm._s(category.name)+" ")])],1)]},proxy:true}],null,true)},[_vm._l((category.categories),function(category2){return [_c('v-list-item',{key:category2.categoryId,staticClass:"category2",attrs:{"link":"","to":{
                name: 'Category',
                params: { pathMatch: category2.slug }
              }}},[_c('v-list-item-title',[_vm._v(_vm._s(category2.name))])],1)]})],2)}),1)],1),(_vm.$vuetify.breakpoint.smAndDown && !_vm.isCordova)?_c('v-tab-item',{key:"info"},[_c('v-list',{staticClass:"info-tab px-2"},[_vm._l((_vm.infoLinks),function(link){return _c('v-list-item',{key:link.templateNavItemId,staticClass:"link accent mb-1",attrs:{"href":link.itemUrl},on:{"click":_vm.clicked}},[_c('v-list-item-title',{staticClass:"text--bold",class:link.classStyle},[_vm._v(" "+_vm._s(link.name)+" ")])],1)}),_vm._l((_vm.menuLinks),function(item){return [(item.children?.length <= 0)?_c('v-list-item',{key:item.templateNavItemId,staticClass:"menuItem",class:item.itemUrl && item.itemUrl.length > 0
                ? 'link-enabled full'
                : 'link-disabled',attrs:{"href":item.itemUrl},on:{"click":_vm.handleClickLink}},[_c('v-list-item-title',{staticClass:"ml-0 w-100",class:item.cssClass},[_vm._v(" "+_vm._s(item.name)+" ")])],1):_c('v-list-group',{key:item.templateNavItemId,staticClass:"menuItem",class:item.itemUrl && item.itemUrl.length > 0
                ? 'link-enabled full'
                : 'link-disabled',scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[(item.itemUrl && item.itemUrl.length > 0)?_c('a',{attrs:{"href":item.itemUrl,"event":""},on:{"click":_vm.handleClickLink}},[_vm._v(" "+_vm._s(item.name))]):_c('span',[_vm._v(_vm._s(item.name))])])]},proxy:true}],null,true)},[_vm._l((_vm.filteredExcludedNavItemNavigationType(
                item.templateNavItems,
                [3]
              )),function(child){return _c('div',{key:child.templateNavItemId},[_c('v-list-item',{attrs:{"link":"","href":child.itemUrl},on:{"click":_vm.clicked}},[_c('v-list-item-title',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(child.name)+" ")])],1),(child.templateNavItems?.length)?_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},_vm._l((child.templateNavItems),function(child2){return _c('v-list-item',{key:child2.templateNavItemId,staticClass:"text-caption default--text ml-3",class:child2.cssClass,attrs:{"link":"","href":child2.itemUrl || '#'},on:{"click":_vm.clicked}},[_vm._v(" "+_vm._s(child2.name)+" ")])}),1):_vm._e()],1)}),_vm._l((_vm.filteredIncludedNavItemNavigationType(
                item.templateNavItems,
                [3]
              )),function(navItemBanner){return _c('MenuBannerCard',{key:navItemBanner.templateNavItemId,attrs:{"bannerCard":navItemBanner}})})],2)]})],2)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }