<template>
  <div>
    <div
      class="accept-replacement"
      v-if="isCheckout && cart.homeDelivery && cartHasItems"
    >
      <v-checkbox
        hide-details
        @click.prevent
        :label="$t('cart.cartDetail.acceptAlternativeForAllProducts')"
        v-model="acceptAlternatives"
      ></v-checkbox>
    </div>
    <v-list
      id="cartItemList"
      flat
      subheader
      class="cart-item-list my-3"
      :class="{ 'checkout-item-list': isCheckout }"
    >
      <template>
        <v-subheader
          class="text-caption d-flex justify-space-between default--text"
        >
          <CartItemCount :count="cart.totalItems" />
          <!-- <v-btn
            color="primary"
            plain
            class="empty-cart-btn d-md-none d-lg-none"
            @click="emptyCartConfirm"
            >{{ $t("cart.emptyCart") }}</v-btn
          > -->
          <v-switch
            v-if="cartHasItems"
            v-model="divideProductsByCategory"
            label="Dividi per categoria"
          ></v-switch>
        </v-subheader>
      </template>

      <div
        v-if="!divideProductsByCategory"
        class="no-sectors"
        :class="[
          { 'cart-item-list-no-checkout': !isCheckout },
          { 'cart-item-list-no-checkout-higher': !isCheckout }
        ]"
      >
        <div
          v-for="cartItem in cart.cartItems"
          :key="cartItem.cartItemId"
          class="align-center px-md-2"
        >
          <CartItem
            :item="cartItem"
            :full="full"
            :isCheckout="isCheckout"
            :showCartButton="showCartButton"
            :isHomeDelivery="cart.homeDelivery"
          />
        </div>
      </div>
      <v-expansion-panels v-else v-model="panel" accordion flat multiple tile>
        <v-expansion-panel
          v-for="[categoryId, group] in itemGroups"
          v-bind:key="categoryId"
        >
          <v-expansion-panel-header
            v-on:click.prevent="() => {}"
            class="text-caption primary--text grey lighten-1 px-3"
          >
            <div class="d-flex align-center grey--text text--darken-1">
              <span class="font-weight-bold mr-2">{{ group.name }}</span>
              <span>
                {{ `(${group.products.length})` }}
              </span>
            </div>
            <template v-slot:actions>
              <v-icon color="secondary" small>
                <!-- {{ panel.includes(index) ? "$chevronUp" : "$chevronDown" }} -->
                $chevronDown
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <CartItem
              class="sectorItem"
              v-for="item in group.products"
              v-bind:key="item.itemId"
              :item="item"
              :full="full"
              :isCheckout="isCheckout"
              :isHomeDelivery="cart.homeDelivery"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-list>
  </div>
</template>
<style lang="scss">
.cart-item-list {
  .v-expansion-panel {
    .v-expansion-panel-header {
      min-height: 20px;
      padding: 10px 24px;
    }
    .v-expansion-panel-content .v-expansion-panel-content__wrap {
      padding: 0px !important;
      .v-list-item {
        padding: 0 16px;
        min-height: 70px;
      }
    }
  }
  .checkout-item-list {
    .no-sectors {
      .v-list-item {
        min-height: 70px !important;
        padding: 8px 0px;
      }
    }
  }
  .v-subheader {
    height: auto;
    max-height: #{$list-subheader-min-height};
    background: $white;
  }
  .cart-item-list-no-checkout {
    min-height: 30vh;
    height: calc(
      100vh - (#{$cart-info-height} + #{$list-subheader-min-height}) - 80px
    );
    //padding-top: #{$list-subheader-min-height};
    //margin-top: -#{$list-subheader-min-height};
    padding-bottom: #{$summary-height};
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--v-grey-darken2);
      border-radius: 20px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .cart-item-list-no-checkout-higher {
    height: calc(
      100vh -
        (
          #{$cart-info-height} + #{$list-subheader-min-height} + #{$cartlist-diff}
        )
    ) !important;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .cart-item-list-no-checkout {
      min-height: auto;
      max-height: 100%;
    }
  }
}
</style>

<script>
import CartItem from "./CartItem.vue";
import CartItemCount from "./CartItemCount.vue";

import { mapActions, mapState } from "vuex";
export default {
  name: "CartItemList",
  components: { CartItem, CartItemCount },
  props: {
    full: { type: Boolean, default: false },
    isCheckout: { type: Boolean, default: false },
    checkMobile: { type: Boolean, default: false },
    showSectors: { type: Boolean, default: true },
    showCartButton: { type: Boolean, default: true }
  },
  data() {
    return {
      panel: [],
      divideProductsByCategory: this.showSectors
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    acceptAlternatives: {
      get() {
        let value = true;
        this.cart.cartItems.forEach(cartItem => {
          if (cartItem.cartItemInfo.accept_alternatives != "true") {
            value = false;
          }
          return value;
        });
        return value;
      },
      set(value) {
        this.setCartItemInfo({
          items: this.cart.cartItems,
          name: "accept_alternatives",
          value: "" + value
        });
      }
    },
    orderComment: {
      get() {
        return this.cart.cartInfos.order_comment;
      },
      set(value) {
        console.log(value);
        //update on blur
      }
    },
    itemGroups() {
      let groups = new Map();
      if (this.cart.cartItems) {
        this.cart.cartItems.forEach(item => {
          let category = this.$store.getters["category/lookupCategory"](
            item.product.categoryId
          );
          if (category === undefined || category === null) {
            category = {
              categoryId: -1,
              name: "Senza categoria"
            };
          }
          if (groups.has(category.categoryId)) {
            groups.get(category.categoryId).products.push(item);
          } else {
            groups.set(category.categoryId, {
              name: category.name,
              products: [item]
            });
          }
        });
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.panel = Array.from(Array(groups.size).keys());
      return groups;
    },
    cartHasItems() {
      return this.cart && this.cart.totalItems > 0;
    }
  },
  methods: {
    handleRemove() {},
    changeTimeslot() {},
    changeDelivery() {},
    ...mapActions({
      // emptyCart: "cart/emptyCart",
      setCartItemInfo: "cart/setCartItemInfo",
      setCartInfo: "cart/setCartInfo"
    })
    // async emptyCartConfirm() {
    //   if (this.cart.totalItems > 0) {
    //     let res = await global.vm.$dialog.confirm({
    //       text: global.vm.$t("message.emptyCart")
    //     });
    //     if (res) {
    //       this.emptyCart();
    //     }
    //   }
    // }
  }
};
</script>
